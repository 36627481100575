<div class="mat-elevation-z8">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
        <!-- Id Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
            <td mat-cell *matCellDef="let row">{{ row.title }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Beschreibung</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="action-col">
                <button mat-icon-button (click)="edit(row)" *ngIf="showActions()"><mat-icon>create</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10]"> </mat-paginator>
</div>