<div class="mi">
    <form [formGroup]="miForm" (ngSubmit)="save()">
        <mat-card class="mi-card">
            <mat-card-title>Eintrag in der Mediensammlung</mat-card-title>
            <mat-spinner *ngIf="!isLoaded()" style="align-self: center"></mat-spinner>
            <mat-card-content *ngIf="isLoaded()">
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Titel</mat-label>
                    <input matInput type="text" formControlName="title" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Beschreibung</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Autor / Prediger</mat-label>
                    <input matInput type="text" formControlName="author" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Datum der Aufzeichnung</mat-label>
                    <input matInput [matDatepicker]="dp" type="text" formControlName="itemDate" />
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp [disabled]="isDisabled()"></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Schlüsselwörter</mat-label>
                    <mat-chip-list #chipList aria-label="Schlüsselwörter selektieren" formControlName="keywords">
                        <mat-chip *ngFor="let keyword of miForm.get('keywords').value" [selectable]="selectable" [removable]="removable" (removed)="remove(keyword)">
                            {{ keyword }}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Neues Schlüsslewort..." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add($event)" />
                    </mat-chip-list>
                </mat-form-field>
                <mat-form-field appearance="fill" class="mi-field">
                    <mat-label>Mediensammlung</mat-label>
                    <mat-select formControlName="mediaCollectionDefinition">
                        <mat-option *ngFor="let mcd of mediaCollectionDefinitions" [value]="mcd.id">{{ mcd.title }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
            <mat-accordion *ngIf="isLoaded() && !isNew()" class="example-headers-align" multi>
                <app-media-item-upload *ngFor="let entry of entries()" [entry]="entry" [definition]="getMediaItemDefinition(entry)" [readOnly]="isDisabled()" (onItemChange)="itemUpdate($event)"></app-media-item-upload>
            </mat-accordion>
            <mat-card-actions align="end" *ngIf="isLoaded()">
                <button *ngIf="canPublish()" mat-button color="primary" type="button" [disabled]="isInvalid()" (click)="publishItem()">Veröffentlichen</button>
                <button *ngIf="canUnpublish()" mat-button color="primary" type="button" (click)="unpublishItem()">Zurückziehen / Bearbeiten</button>
                <button *ngIf="canSave()" mat-button color="primary" type="button" [disabled]="isInvalid()" (click)="save()">Speichern</button>
                <button *ngIf="isNew()" mat-button color="primary" type="button" [disabled]="isInvalid()" (click)="create()">Erstellen</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
