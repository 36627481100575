<div class="mcd">
    <form [formGroup]="mcdForm" (ngSubmit)="save()">
        <mat-card class="mcd-card">
            <mat-card-title>Definition der Mediensammlung</mat-card-title>
            <mat-spinner *ngIf="!isLoaded()" style="align-self: center"></mat-spinner>

            <mat-card-content *ngIf="isLoaded()">
                <mat-form-field appearance="fill" class="mcd-field">
                    <mat-label>Titel</mat-label>
                    <input matInput type="text" formControlName="title" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="mcd-field">
                    <mat-label>Beschreibung</mat-label>
                    <textarea matInput formControlName="description"></textarea>
                </mat-form-field>
                <div class="mcd-addbutton">
                    <button mat-icon-button color="primary" type="button" (click)="addItem()"><mat-icon>add</mat-icon></button>
                </div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" width="100%" multiTemplateDataRows>
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>Titel</th>
                        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
                    </ng-container>

                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef>Technische ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.key }}</td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>Type</th>
                        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button *ngIf="!isItemEdited()" (click)="editItem(element)"><mat-icon>create</mat-icon></button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div [@detailExpand]="element == itemToEdit ? 'expanded' : 'collapsed'">
                                <app-media-item-definition-component *ngIf="element === itemToEdit" [item]="element" (onEditEnding)="itemEdited($event)" [hasDelete]="itemsCanBeDeleted()">
                                </app-media-item-definition-component>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="item-detail-row"></tr>
                </table>
            </mat-card-content>
            <mat-card-actions align="end" *ngIf="isLoaded()"><button mat-button color="primary" type="submit" [disabled]="isDisabled()">Speichern</button> </mat-card-actions>
        </mat-card>
    </form>
</div>