<div class="root">
    <div class="navbar">
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon class="hamburger-icon" aria-label="Side nav toggle icon">{{ drawer.opened ? "menu_open" : "menu" }}</mat-icon>
      </button>
            <span class="text">Media Library</span>
            <span class="filler"></span>
            <!-- TODO: Profile picture implementation -->
            <div *ngIf="isLoggedIn()" class="userbox" [matMenuTriggerFor]="menu">
                <div class="usernamebox">
                    <span class="userbox-line">{{ getUserName() }}</span> <span class="userbox-line">{{ getEmail() }}</span>
                </div>
                <mat-icon class="profile">account_circle</mat-icon>
            </div>
            <!--  <img [matMenuTriggerFor]="menu" class="profile" alt="p" [attr.src]="getProfilePicture() || '../assets/pfp.svg'" /> -->
        </mat-toolbar>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">Abmelden</button>
        </mat-menu>
    </div>

    <mat-drawer-container class="example-container" hasBackdrop="false">
        <mat-drawer #drawer mode="side">
            <mat-nav-list>
                <a mat-list-item routerLink="/overview">Übersicht</a>
            </mat-nav-list>
        </mat-drawer>
        <mat-drawer-content>
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>