<div class="grid-container">
    <h1 class="mat-h1">Übersicht</h1>
    <mat-grid-list cols="{{ (cardLayout | async)?.columns }}" rowHeight="200px">
        <!--Mini Cards-->
        <mat-grid-tile *ngFor="let i of [1, 2, 3, 4]" [colspan]="(cardLayout | async)?.miniCard.cols" [rowspan]="(cardLayout | async)?.miniCard.rows">
            <app-card title="Card {{ i }}">
                <div>Mini Card Content Here</div>
            </app-card>
        </mat-grid-tile>
        <!--Table-->
        <mat-grid-tile [colspan]="(cardLayout | async)?.table.cols" [rowspan]="(cardLayout | async)?.table.rows">
            <app-card title="Medieneinträge" createButtonTitle="Neuer Medieneintrag" (onButtonClicked)="createMediaItem()">
                <app-media-item-table (onRowClick)="openMediaItem($event)"></app-media-item-table>
            </app-card>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="(cardLayout | async)?.definitions.cols" [rowspan]="(cardLayout | async)?.definitions.rows">
            <app-card title="Mediensammlungen" [menuItems]="mcdMenuItems" (onMenuClicked)="doMCDMenuItemClick($event)">
                <div>
                    <app-mcd-table></app-mcd-table>
                </div>
            </app-card>
            <!--Charts-->
            <mat-grid-tile *ngFor="let i of [5, 6]" [colspan]="(cardLayout | async)?.chart.cols" [rowspan]="(cardLayout | async)?.chart.rows">
                <app-card title="Card {{ i }}">
                    <div>Chart Content Here</div>
                </app-card>
            </mat-grid-tile>
        </mat-grid-tile>
    </mat-grid-list>
</div>
