<!--card.component.html-->
<mat-card class="dashboard-card">
    <mat-card-header>
        <mat-card-title>
            {{ title }}
            <div class="more-button">
                <button *ngIf="hasCreateButton()" mat-raised-button color="primary" (click)="executeOnButtonClicked($event)">{{ createButtonTitle }}</button>
                <button *ngIf="hasMenuItems()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Toggle menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button *ngFor="let item of menuItems" mat-menu-item (click)="executeOnMenuClicked(item.actionVerb)">{{ item.title }}</button>
                    <button mat-menu-item>Remove</button>
                </mat-menu>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="dashboard-card-content">
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>
