import { DataSource } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { map } from "rxjs/operators";
import { Observable, merge, BehaviorSubject } from "rxjs";
import { MediaItem } from "../models/media-item";
import { MediaItemService } from "../services/media-item.service";

/**
 * Data source for the MediaItemTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class MediaItemTableDataSource extends DataSource<MediaItem> {
  loading = true;
  paginator: MatPaginator;
  sort: MatSort;
  dataContainer$: BehaviorSubject<MediaItem[]> = new BehaviorSubject<MediaItem[]>([]);
  searchValue$ = new BehaviorSubject<string>('');

  constructor(private service: MediaItemService) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<MediaItem[]> {
    this.loading = true;
    this.service.getAllMediaItem().subscribe((result) => {
      this.loading = false;
      this.dataContainer$.next(result);
    });

    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [this.dataContainer$.asObservable(), this.paginator.page, this.sort.sortChange, this.searchValue$.asObservable()];

    return merge(...dataMutations).pipe(
      map(() => {
        return this.getPagedData(this.getSortedData(this.getFilteredData([...this.dataContainer$.getValue()])));
      })
    );
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  filter(value: string): void {
    this.searchValue$.next(value);
  }
  
  getItemCount(): number {
    return this.dataContainer$.getValue().length;
  }

  private getFilteredData(data:MediaItem[]):MediaItem[] {
    const search = this.searchValue$.getValue();
    if (search == ''){
      return data;
    }
    return data.filter(item=>this.contains(item.author,search) || this.contains(item.titel, search) || this.contains(item.itemDate,search))
  }
  private contains(value:string,search:string):boolean {
    return value && value.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >-1;
  }
  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: MediaItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: MediaItem[]) {
    if (!this.sort.active || this.sort.direction === "") {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === "asc";
      if (this.sort.active == 'date') {
        console.log(a.itemDate, b.itemDate);
      }
      switch (this.sort.active) {
        case "titel":
          return compare(a.titel, b.titel, isAsc);
        case "date":
          return compare(a.itemDate, b.itemDate, isAsc);
        case "author":
          return compare(a.author, b.author, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
