<div class="mat-elevation-z8">
    <div style="padding: 20px" *ngIf="loading()">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div style="display: flex;margin-left: 20px; padding-top: 20px;">
        <mat-form-field>
            <mat-label>Suche</mat-label>
            <input matInput placeholder="Stefan Kym" [formControl]="searchField">
        </mat-form-field>
    </div>
    <table mat-table class="full-width-table" matSort aria-label="Elements">
        <!-- Name Column -->
        <ng-container matColumnDef="publish">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="50px"></th>
            <td mat-cell *matCellDef="let row">
                <mat-icon>{{ row.published ? "check_circle" : "unpublished" }}</mat-icon>
            </td>
        </ng-container>
        <ng-container matColumnDef="titel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
            <td mat-cell *matCellDef="let row">{{ row.titel }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum der Aufzeichnung</th>
            <td mat-cell *matCellDef="let row">{{ row.itemDate | date: "shortDate" }}</td>
        </ng-container>

        <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Autor / Prediger</th>
            <td mat-cell *matCellDef="let row">{{ row.author }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="tableRow" (click)="rowClick(row)"></tr>
    </table>

    <mat-paginator #paginator [length]="dataSource?.getItemCount()" [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[25, 50]"> </mat-paginator>
</div>