<div class="login-main" *ngIf="showLogin()">
    <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
        <mat-card class="login-card">
            <mat-card-title>Login</mat-card-title>
            <mat-card-content>
                <mat-form-field appearance="fill" class="login-field">
                    <mat-label>Benutzername</mat-label>
                    <input matInput type="text" formControlName="username" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="login-field">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="password" formControlName="password" />
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-button color="primary" type="submit" [disabled]="!canLogin()" [class.button-spinner]="loading">Login</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
<div class="login-main" *ngIf="noAccess">
    <mat-card class="login-card">
        <mat-card-title>Kein Zugriff</mat-card-title>
        <mat-card-content> Leider hast du keinen Zugriff auf die Media Verwaltung. Bitte wende dich an den Administrator. </mat-card-content>
        <mat-card-actions align="end">
            <button mat-button color="primary" type="button" (click)="doLogout()">Abmelden</button>
        </mat-card-actions>
    </mat-card>
</div>