<form [formGroup]="midForm" (onSubmit)="doSubmit()">
    <mat-card class="ItemCard">
        <mat-card-content class="ItemCardContent">
            <mat-slide-toggle formControlName="activeToggle" color="primary" class="ItemSlider">Aktiv</mat-slide-toggle>
            <mat-form-field appearance="fill" class="mcd-field">
                <mat-label>Key</mat-label>
                <input matInput type="text" formControlName="key" />
            </mat-form-field>
            <mat-form-field appearance="fill" class="mcd-field">
                <mat-label>Titel</mat-label>
                <input matInput type="text" formControlName="title" />
            </mat-form-field>
            <mat-form-field appearance="fill" class="mcd-field">
                <mat-label>Type</mat-label>
                <mat-select formControlName="mediaType">
                    <mat-option value="audio">Audio</mat-option>
                    <mat-option value="pdf">Pdf</mat-option>
                    <mat-option value="video">Video</mat-option>
                    <mat-option value="picture">Bild</mat-option>
                    <mat-option value="link">Link</mat-option>
                    <mat-option value="text">Text</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="mcd-field">
                <mat-label>Beschreibung</mat-label>
                <textarea matInput formControlName="description"></textarea>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-icon-button color="warn" type="button" *ngIf="showDelete()" (click)="doDelete()"><mat-icon>delete</mat-icon></button>
            <button mat-icon-button color="accent" type="button" (click)="doCancel()"><mat-icon>clear</mat-icon></button>
            <button mat-icon-button color="primary" type="submit" [disabled]="!isValid()" (click)="doSubmit()"><mat-icon>done</mat-icon></button>
        </mat-card-actions>
    </mat-card>
</form>
